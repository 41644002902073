import React, {Component} from 'react';
import {Col, Row, Tooltip, TabContent, TabPane, Spinner, Alert} from 'reactstrap';
import {connect} from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import {
  changeBackButton,
  fetchTestDetailsAverages,
  fetchUserActivityAnalytics,
  fetchUserStatus,
  fetchUserDevice,
  fetchUserStatusActivity,
  fetchUserData,
  fetchUserRecentActivity,
  getMyProfile,
  requestRemoteTest,
  fetchUserIssuesLogs,
} from '../../store/actions';
import { getApplicationsActivity } from '../../store/application/actions';
import UserDetailsTabs from '../../components/UserDetailsTabs';
import Speedometer from '../../components/Speedometer';
import Scale from '../../components/Scale';
import wifiGood from '../../images/wifi-good.svg';
import wifiDecent from '../../images/wifi-decent.svg';
import DevicePerformance from './DevicePerformance';
import Applications from './Applications';
import AhoyTrack from '../../modules/AhoyTrack';
import Scatterchart from '../../components/Scatterchart';
import UsersTable from '../../components/user-status/network/UsersTable';
import MapBox from '../../components/MapBox/MapBox';
import TestDetailsDrawer from '../../components/drawers/TestDetailsDrawer';
import AiResponse from '../../components/aiResponse/AiResponse';
import InternetPerformance from "../../components/test-results/internet-performance";
import HopsSummary from "../../components/hops/hopsSummary/HopsSummary";
import ComponentLoader from '../../components/ComponentLoader';
import Button from '../../components/Button/Button';
import IssuesTable from "../../components/tables/Issues/IssuesTable";
import UserOnlineStatus from "../../components/UserOnlineStatus/UserOnlineStatus";

class UserStatus extends Component {
  constructor(props) {
    AhoyTrack();
    super(props);
    this.state = {
      filter: null,
      sortBy: null,
      tooltipOpen1: false,
      tooltipOpen2: false,
      tooltipOpen3: false,
      tooltipOpen4: false,
      tooltipOpen5: false,
      tooltipOpen6: false,
      tooltipOpen7: false,
      tooltipOpen8: false,
      tooltipOpen9: false,
      tooltipOpen10: false,
      tooltipOpen11: false,
      tooltipOpen12: false,
      tooltipOpen13: false,
      activeTab: '0',
      isDrawerOpen: false,
      testId: null,
    };
  }

  componentDidMount() {
    const {
      fetchUserActivityAnalytics,
      match,
      fetchUserDevice,
      getApplicationsActivity,
      location,
      fetchUserStatusActivity,
      fetchTestDetailsAverages,
      userData,
      fetchUserData,
      fetchUserRecentActivity,
      fetchUserIssuesLogs,
      getMyProfile,
    } = this.props;
    fetchUserActivityAnalytics(match.params.id);
    fetchUserData(match.params.id);
    fetchUserRecentActivity(match.params.id);
    fetchUserDevice(match.params.id);
    fetchUserStatusActivity(match.params.id);
    getApplicationsActivity(match.params.id);
    fetchTestDetailsAverages(match.params.id);
    fetchUserIssuesLogs(match.params.id);
    getMyProfile();

    const params = queryString.parse(location.search);
    if (params?.activetab === 'device') {
      this.setState({
        activeTab: '1',
      });
    }
    if (params?.activetab === 'applications') {
      this.setState({
        activeTab: '2',
      });
    }
  }

  onMapMarkerClick = (id) => {
    this.setState({
      testId: id,
      isDrawerOpen: true,
    });
  };

  onDrawerClose = () => {
    this.setState({
      testId: null,
      isDrawerOpen: false,
    });
  };

  navigateToPage = (page) => {
    this.fetchData(page);
  }

  onTableSortChange = (sortBy) => {
    if (sortBy) {
      this.setState({
        sortBy,
      }, () => this.fetchData());
    }
  }

  onFilterChange = (filter) => {
    if (filter) {
      this.setState({filter}, () => this.fetchData());
    }
  }

  fetchData = (page) => {
    const {fetchUserStatus, match, history} = this.props;
    const {filter, sortBy} = this.state;
    let queryParams = {};

    if (page) {
      queryParams.page = page;
    }

    if (sortBy) {
      queryParams.sort_by = sortBy;
    }

    if (filter) {
      queryParams = {...queryParams, ...filter};
    }

    fetchUserStatus(history, match.params.id, queryParams);
  }

  getMapCenter = () => {
    const {mapMarkers} = this.props;
    if (mapMarkers.length <= 0) {
      return undefined;
    }

    return {lat: mapMarkers[0].lat, lng: mapMarkers[0].lng};
  }

  toggle = (id) => {
    const {
      tooltipOpen1, tooltipOpen2, tooltipOpen3, tooltipOpen4, tooltipOpen5, tooltipOpen6,
      tooltipOpen7, tooltipOpen8, tooltipOpen9, tooltipOpen10, tooltipOpen11, tooltipOpen12,
      tooltipOpen13,
    } = this.state;
    switch (id) {
      case 1:
        this.setState({tooltipOpen1: !tooltipOpen1});
        break;
      case 2:
        this.setState({tooltipOpen2: !tooltipOpen2});
        break;
      case 3:
        this.setState({tooltipOpen3: !tooltipOpen3});
        break;
      case 4:
        this.setState({tooltipOpen4: !tooltipOpen4});
        break;
      case 5:
        this.setState({tooltipOpen5: !tooltipOpen5});
        break;
      case 6:
        this.setState({tooltipOpen6: !tooltipOpen6});
        break;
      case 7:
        this.setState({tooltipOpen7: !tooltipOpen7});
        break;
      case 8:
        this.setState({tooltipOpen8: !tooltipOpen8});
        break;
      case 9:
        this.setState({tooltipOpen9: !tooltipOpen9});
        break;
      case 10:
        this.setState({tooltipOpen10: !tooltipOpen10});
        break;
      case 11:
        this.setState({tooltipOpen11: !tooltipOpen11});
        break;
      case 12:
        this.setState({tooltipOpen12: !tooltipOpen12});
        break;
      case 13:
        this.setState({tooltipOpen13: !tooltipOpen13});
        break;
    }
  };

  getIspDataSet = () => {
    const {activityAnalytics} = this.props;
    if (activityAnalytics && activityAnalytics?.activities_per_isp.length > 0) {
      return activityAnalytics.activities_per_isp.map(({number_of_activities, isp}) => ({
        value: number_of_activities,
        name: isp,
      }));
    }
    return [];
  }

  toggleTabs = (tab) => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  systemName = (name) => {
    const lowerName = name?.toLowerCase();
    switch (lowerName) {
      case 'win':
        return 'Windows';
      case ('mac'):
        return 'MacOS';
      case ('cros'):
        return 'Chrome OS';
      case ('lin'):
        return 'Linux';
      default:
        return name;
    }
  };

  render() {
    const {
      data,
      match,
      activityAnalytics,
      activityAnalyticsLoading,
      testData,
      testDetailsAveragesData,
      testDetailsAveragesLoading,
      device,
      appsActivity,
      userProfile,
      appsLoading,
      userStatusActivityData,
      userStatusActivityLoading,
      userData,
      userDataIsLoading,
      isAiFeaturesEnabled,
      userRecentActivities,
      userRecentActivitiesLoading,
      requestRemoteTest,
      requestRemoteResultText,
      requestRemoteResultIsSuccess,
      requestRemoteTestLoading,
      userIssueData,
      userIssueDataLoading,
    } = this.props;
    const {
      tooltipOpen4, tooltipOpen5,
      activeTab,
      isDrawerOpen,
      testId,
    } = this.state;
    const isGood = testDetailsAveragesData?.packet_loss < 6;
    const {device_lan, lan_wan, wan_cloud} = testDetailsAveragesData
    const tabsArr = [
      {
        title: 'Summary',
        disabled: false,
        warning: false,
      },
      {
        title: 'Network',
        disabled: false,
        warning: false,
      },
      {
        title: 'Device',
        disabled: !device?.test_id,
        warning: (
          (device?.avg_cpu_utilization > 21 && device?.avg_cpu_utilization <= 31)
          || (device?.avg_memory_utilization > 18)
          || (device?.avg_battery_health === 0)
        ),
      },
      {
        title: 'Applications',
        disabled: !(appsActivity?.recent_activities?.length > 0 || appsActivity?.unencrypted_site_visit || appsActivity?.average_page_load),
        warning: appsActivity?.recent_activities?.find((item) => item.reachable === false),
      },
    ];

    const networkTestsArr = [
      {
        label: 'Good',
        value: activityAnalytics?.good_tests_today,
        textColor: 'text-good',
        isLoading: activityAnalyticsLoading,
      },
      {
        label: 'Decent',
        value: activityAnalytics?.decent_tests_today,
        textColor: 'text-decent',
        isLoading: activityAnalyticsLoading,
      },
      {
        label: 'Bad',
        value: activityAnalytics?.poor_tests_today,
        textColor: 'text-poor',
        isLoading: activityAnalyticsLoading,
      },
    ];

    const daysAfterRestart = () => {
      const restartDate = moment(data?.user_activities?.[0]?.last_restart_time);
      const dateNow = moment().local();
      const daysPast = dateNow.diff(restartDate, 'days');
      if (daysPast > 15) {
        return <span className="danger">{daysPast} day{daysPast !== 1 ? 's' : ''} ago</span>;
      }
      if (daysPast > 0) {
        return <span>{daysPast} day{daysPast !== 1 ? 's' : ''} ago</span>;
      }
      return <span>Today</span>;
    };

    const aiPostData = {
      user_id: match.params.id
    }

    const userDetailsInfoItem = (name, value) => {
      return (
        <div className="item">
          <div className="item-name">{name}</div>
          <div className="item-value">{value}</div>
        </div>
      );
    };
    const isHopsNull = (device_lan === null && lan_wan === null && wan_cloud === null);
    const isHopsZero = (device_lan === 0 && lan_wan === 0 && wan_cloud === 0);
    const runRemoteTest = () => {
      requestRemoteTest(userData?.guid);
    }
    
    return (
      <>
        <div className="container-fluid content user-details">
          <Row>
            <Col>
              <div className="user-details-top">
                {userDataIsLoading && <div className="spinner-wrapper"><Spinner color="primary" /></div>}
                <div className="user-details-user">
                  <UserOnlineStatus />
                  <div className="name">{userData?.email || 'Public User'}</div>
                  <Button
                    onClick={runRemoteTest}
                    disabled={requestRemoteTestLoading || requestRemoteResultIsSuccess}
                    className="check-network-health-btn"
                  >
                    {requestRemoteResultText}
                  </Button>
                </div>
                <Row>
                  <Col md="6">
                    <div className="user-details-info">
                      {device?.operating_system && userDetailsInfoItem(
                        'Device',
                        this.systemName(device?.operating_system)
                      )}
                      {userData?.guid && userDetailsInfoItem(
                        'Guid',
                        userData?.guid
                      )}
                      {userData?.last_chromebook_serial && userDetailsInfoItem(
                        'Serial Number',
                        userData?.last_chromebook_serial
                      )}
                      {userData?.last_restart_time && userDetailsInfoItem(
                        'Last Restart',
                        <>
                          {moment(userData?.last_restart_time).format('MMMM DD, YYYY')}
                          {daysAfterRestart()}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
                {userIssueData.length > 0 ? (
                  <Row className="mt-3 custom_table_style">
                    <Col md="12">
                      <div>
                        <IssuesTable
                          userId={+match.params.id}
                          data={userIssueData}
                          isLoading={userIssueDataLoading}
                          onSortChange={false}
                        />
                      </div>
                    </Col>
                  </Row>
                ) : <div className="mt-3">No current or past issues for this user</div>}
              </div>
              <div className="user-details-tabs">
                <UserDetailsTabs activeTab={activeTab} toggleTabs={this.toggleTabs} tabs={tabsArr} />
              </div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="0" className="tab_summary">
                  <div style={{position: 'relative', zIndex: '2'}}>
                    {isAiFeaturesEnabled && (
                      <div className="mb-5">
                        <AiResponse
                          title="general summary result"
                          type="userSummaryTab"
                          data={aiPostData}
                        />
                      </div>
                    )}
                    <div className="tab_summary_wrapper">
                      <div className="tab_summary_network_and_tests">
                        <div className="tab_summary_network_and_tests_title">
                          <div className="tab-block-title">
                            Latest Results
                            <span>1W</span>
                          </div>
                          <div className="tab-block-subtitle">
                            The latest network performance details for this user.
                          </div>
                        </div>
                        <div className="tab_summary_network_and_body">
                          {(!isHopsNull && !isHopsZero) && (
                            <div className="user-test-block">
                              <HopsSummary hops={testDetailsAveragesData}/>
                              <div className="divider" />
                            </div>
                          )}
                          <div className="user-test-block block">
                            <InternetPerformance
                              displayTitle={false}
                              download={testDetailsAveragesData?.download}
                              upload={testDetailsAveragesData?.upload}
                              jitter={testDetailsAveragesData?.jitter}
                              latency={testDetailsAveragesData?.latency}
                              packetLoss={testDetailsAveragesData?.packet_loss}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="tab_summary_location">
                        <div className="tab_summary_location_title">
                          <div className="tab-block-title">
                            Location
                            <span>1W</span>
                          </div>
                          <div className="tab-block-subtitle">Where individual test data was collected over the last
                            week.
                          </div>
                        </div>
                        <div className="tab_summary_location_body">
                          {userRecentActivitiesLoading && <ComponentLoader />}
                          {userRecentActivities && !userRecentActivitiesLoading && (
                            <MapBox
                              markers={userRecentActivities?.features}
                              displayOnBoarding={false}
                              noClick
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="1" className="tab_network">
                  {isAiFeaturesEnabled && (
                    <div className="mb-5">
                      <AiResponse
                        title="network experience summary"
                        type="userNetworkTab"
                        data={aiPostData}
                      />
                    </div>
                  )}
                  <div className="tab-block">
                    <Row>
                      <Col xs="12" md="3">
                        <div className="network-tests">
                          <div className="tab-block-title">
                            Network Tests
                            <span>1W</span>
                          </div>
                          <div className="tab-block-subtitle">
                            A snapshot of {userData?.email || 'user'}’s network performance from the last week.
                          </div>

                          <div className="tab-block tab-block_network">
                            <div className="tab-block-title">
                              Totals (1W)
                            </div>
                            <div className="list-block">
                              {networkTestsArr.map((item) => (
                                <div className="list-block-item" key={item.label}>
                                  {item.isLoading && <div className="d-flex justify-content-center w-100"><CircularProgress size={28} /></div>}
                                  {!item.isLoading && (
                                    <>
                                      <div className="list-block-label">{item.label}</div>
                                      <div className={`list-block-value ${item.textColor}`}>{item.value}</div>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="12" md="9">
                        <div id="scatter-chart" className="e-chart">
                          {userProfile?.id && (
                            <Scatterchart sessionId={+match.params.id || userProfile?.id} onMarkerClick={this.onMapMarkerClick} />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {data?.user_activities?.length !== 0 && (
                    <div className="tab-block">
                      <div className="tab-block-title">
                        Latest Results
                        <span>1W</span>
                      </div>
                      <div className="tab-block-subtitle">
                        The latest network performance details for this user.
                      </div>
                      <Row>
                        <Col md="3">
                          <div className="charts-wrapper">
                            <Speedometer
                              maxValue={100}
                              value={testDetailsAveragesData?.download?.toFixed(2)}
                              width="124"
                              gradient="90deg, rgba(249,52,52,1) 0%, rgba(221,200,1,1) 38%, rgba(5,190,56,1) 100%"
                              unitLabel="MBPS"
                              label="Download"
                              isLoading={testDetailsAveragesLoading}
                            />
                            <div className="separator" />
                            <Scale
                              maxValue={100}
                              value={testDetailsAveragesData?.per_download?.toFixed(2)}
                              gradient="90deg, rgba(249,52,52,1) 0%, rgba(221,200,1,1) 38%, rgba(5,190,56,1) 100%"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="charts-wrapper">
                            <Speedometer
                              maxValue={100}
                              value={testDetailsAveragesData?.upload?.toFixed(2)}
                              width="124"
                              gradient="90deg, rgba(249,52,52,1) 0%, rgba(221,200,1,1) 38%, rgba(5,190,56,1) 100%"
                              unitLabel="MBPS"
                              label="Upload"
                              isLoading={testDetailsAveragesLoading}
                            />
                            <div className="separator" />
                            <Scale
                              maxValue={100}
                              value={testDetailsAveragesData?.per_upload?.toFixed(2)}
                              gradient="90deg, rgba(249,52,52,1) 0%, rgba(221,200,1,1) 38%, rgba(5,190,56,1) 100%"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="charts-wrapper">
                            <Speedometer
                              maxValue={100}
                              value={testDetailsAveragesData?.latency?.toFixed(2)}
                              width="124"
                              gradient="90deg, rgba(5,190,56,1) 0%, rgba(221,200,1,1) 38%, rgba(249,52,52,1) 100%"
                              unitLabel="MS"
                              label="Latency"
                              tooltip="High latency can result in slow response times and echoey video valls."
                              isLoading={testDetailsAveragesLoading}
                            />
                            <div className="separator" />
                            <Scale
                              maxValue={100}
                              value={testDetailsAveragesData?.per_latency?.toFixed(2)}
                              gradient="90deg, rgba(249,52,52,1) 0%, rgba(221,200,1,1) 38%, rgba(5,190,56,1) 100%"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="charts-wrapper">
                            <Speedometer
                              maxValue={100}
                              value={testDetailsAveragesData?.jitter?.toFixed(2)}
                              width="124"
                              gradient="90deg, rgba(5,190,56,1) 0%, rgba(221,200,1,1) 38%, rgba(249,52,52,1) 100%"
                              unitLabel="MS"
                              label="Jitter"
                              tooltip="High jitter can result in slow response times and echoey video calls."
                              isLoading={testDetailsAveragesLoading}
                            />
                            <div className="separator" />
                            <Scale
                              maxValue={100}
                              value={testDetailsAveragesData?.per_jitter?.toFixed(2)}
                              gradient="90deg, rgba(249,52,52,1) 0%, rgba(221,200,1,1) 38%, rgba(5,190,56,1) 100%"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {// eslint-disable-next-line
                  }{data?.user_activities?.length !== 0 && !isNaN(parseInt(testDetailsAveragesData?.packet_loss, 10)) && (
                    <div className="tab-block">
                      <Row>
                        <Col xs="12" md="6">
                          <div className="tab-card">
                            {testDetailsAveragesLoading
                              ? <div className="spinner-wrapper"><CircularProgress size={28} /></div>
                              : (
                                <>
                                  <div className="tab-card-title">
                                    Packet loss
                                    <span>
                                      <i id="pl" className="mdi mdi-information" />
                                      <Tooltip placement="top" isOpen={tooltipOpen4} target="pl" toggle={() => this.toggle(4)}>
                                        Poor signal strengh & Packet loss can indicate that the user may need to relocate to somewhere closer to their router.
                                      </Tooltip>
                                    </span>
                                  </div>
                                  <div className="tab-card-value">
                                    {testDetailsAveragesData?.packet_loss?.toFixed(2) ?? '0'}%
                                  </div>
                                </>
                              )}
                          </div>
                        </Col>
                        <Col xs="12" md="6">
                          <div className="tab-card">
                            {testDetailsAveragesLoading
                              ? <div className="spinner-wrapper"><CircularProgress size={28} /></div>
                              : (
                                <>
                                  <div className="tab-card-title">
                                    WiFi/Lan signal quality
                                    <span>
                                      <i id="wl" className="mdi mdi-information" />
                                      <Tooltip placement="top" isOpen={tooltipOpen5} target="wl" toggle={() => this.toggle(5)}>
                                        Poor signal strengh & Packet loss can indicate that the user may need to relocate to somewhere closer to their router.
                                      </Tooltip>
                                    </span>
                                  </div>
                                  <div className="tab-card-value">
                                    <div className="wifi-status-icon">
                                      {isGood
                                        ? <img src={wifiGood} alt="wifi good" className="img-responsive" />
                                        : <img src={wifiDecent} alt="wifi decent" className="img-responsive" />}
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {/* <Row>
                    <Col className="col-12 col-lg-6">
                      <ISPChartCard
                        title={['Internet Service Providers Used ', <span style={{fontWeight: 'bold'}} key={1}> Past 72 Hours </span>]}
                        tooltip="This report shows which internet service providers are being used by this user over this time period"
                      >
                        <ISPBar height={150} dataSet={this.getIspDataSet()} />
                      </ISPChartCard>
                    </Col>
                  </Row> */}

                  <div className="tab-block">
                    <div className="tab-block-title">
                      Location
                      <span>1W</span>
                    </div>
                    <div className="tab-block-subtitle">Where individual test data was collected over the last week.</div>
                    {userStatusActivityLoading && <div className="spinner-wrapper text-center"><Spinner color="primary" /></div>}
                    {(!userStatusActivityLoading && activeTab === '1') && (
                      <div className="map-wrapper">
                        <MapBox
                          markers={userStatusActivityData?.features}
                          onMapMarkerClick={this.onMapMarkerClick}
                        />
                      </div>
                    )}
                  </div>

                  <Row className="align-items-center">
                    <Col xl={12}>
                      <UsersTable
                        data={data}
                        match={match}
                        onMapMarkerClick={this.onMapMarkerClick}
                      />
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="2" className="tab_device">
                  <DevicePerformance userId={match.params.id} />
                </TabPane>
                <TabPane tabId="3" className="tab_applications">
                  <Applications
                    appsActivity={appsActivity}
                    appsLoading={appsLoading}
                    userId={match.params.id}
                  />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
        <TestDetailsDrawer
          isOpen={isDrawerOpen}
          onHandlerClose={this.onDrawerClose}
          testId={testId}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    error,
    loading,
    data,
    device,
    userStatusActivityData,
    userStatusActivityLoading,
    userData,
    userDataIsLoading,
  } = state.UserStatus;
  const { data: userIssueData, loading: userIssueDataLoading } = state.UserIssuesLogs;
  const {data: activityAnalytics, loading: activityAnalyticsLoading} = state.UserActivityAnalytics;
  const {data: userRecentActivities, loading: userRecentActivitiesLoading} = state.UserRecentActivities;
  const {
    data: testData,
    loading: testLoading,
    testDetailsAveragesData,
    testDetailsAveragesLoading,
  } = state.TestDetails;
  const { appsActivity, loading: appsLoading } = state.Applications;
  const { data: userProfile } = state.Profile;
  const { ai_features_enabled: isAiFeaturesEnabled } = state.Company.companyDetails;
  const { requestRemoteResultText, requestRemoteResultIsSuccess, requestRemoteTestLoading } = state.UserStatus;

  return {
    error,
    loading,
    data,
    activityAnalytics,
    activityAnalyticsLoading,
    testData,
    testLoading,
    testDetailsAveragesData,
    testDetailsAveragesLoading,
    device,
    appsActivity,
    appsLoading,
    userProfile,
    userStatusActivityData,
    userStatusActivityLoading,
    userData,
    userDataIsLoading,
    isAiFeaturesEnabled,
    userRecentActivities,
    userRecentActivitiesLoading,
    requestRemoteResultText,
    requestRemoteResultIsSuccess,
    requestRemoteTestLoading,
    userIssueData,
    userIssueDataLoading,
  };
};

export default connect(mapStateToProps, {
  changeBackButton,
  fetchUserStatus,
  fetchUserIssuesLogs,
  fetchUserStatusActivity,
  fetchUserDevice,
  fetchUserActivityAnalytics,
  fetchTestDetailsAverages,
  getApplicationsActivity,
  fetchUserData,
  fetchUserRecentActivity,
  getMyProfile,
  requestRemoteTest,
})(UserStatus);
