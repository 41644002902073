import * as type from './actionTypes';

const initialState = {
  error: null,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_MY_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        history: action.history,
      };
    case type.GET_MY_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case type.GET_MY_PROFILE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case type.UPDATE_MY_PROFILE_REQUEST:
      return {
        ...state,
        history: action.history,
        loading: true,
      };
    case type.UPDATE_MY_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case type.UPDATE_MY_PROFILE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case type.UPDATE_LAST_RESTART_TIME:
      return {
        ...state,
        data: {
          ...state.data,
          last_restart_time_threshold: action.payload,
        },
      };
    case type.UPDATE_MY_PROFILE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          last_restart_time_threshold: action.payload,
        },
      };
    default:
      return state;
  }
};
