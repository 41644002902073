import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Row } from 'reactstrap'
import apiClient from '../gears/apiClient';
import { toastr } from 'react-redux-toastr';
import BillingForm from './forms/BillingForm';

const SubscriptionAndBilling = ({profile}) => {
  const onManageClick = async () => {
    try {
      const result = await apiClient.get('/verify-subscription');
      if (result.sessionId) {
        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUB_KEY);
        await stripe.redirectToCheckout({sessionId: result.sessionId});
      } else {
        window.location.href = result.url;
      }
    } catch (error) {
      toastr.error('System', error.message);
    }
  };

  return (
    <Row>
      <Col sm={6}>
        <div className="profile-top-title">Subscription & Billing</div>
        {profile.main_contact_user
          ? (
            <>
              <div className="profile-top-text">
                See billable usage, manage subscription, and edit payment information. Billing portal is only
                available to the primary account owner.
              </div>
              <Button
                color="primary"
                onClick={onManageClick}
              >
                {profile.subscription_status === 'trial' ? 'Subscribe Now' : 'Manage'}
              </Button>
            </>
          )
          : (
            <div className="profile-top-text">
              To manage your subscription and billing information, please contact your company’s account owner.
            </div>
          )
        }
      </Col>
      {profile.main_contact_user && (
        <Col sm={6}>
          <BillingForm />
        </Col>
      )}
    </Row>
  )
}

SubscriptionAndBilling.propTypes = {
  profile: PropTypes.object
}

export default SubscriptionAndBilling