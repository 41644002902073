import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import moment from 'moment';
import ReactTable from '../ReactTable';
import UserForm from '../forms/UserForm';
import { deleteUser } from '../../store/actions';
import Button from '../Button/Button';
import { capitalizeFirstLetter } from '../../helpers/page_helper';

const UsersTable = ({
  fetch,
  data,
  isLoading,
  onSortChange,
  disableSortBy = false,
  dispatchDeleteUser,
  guids,
  setGuids
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [modalContent, setModalContent] = useState();

  const toggleGuids = (guid) => {
    const isGuidSelected = guids.includes(guid);
    if(isGuidSelected) {
      const copyGuids = [...guids];
      const modifiedGuids = copyGuids.filter(item => item !== guid);
      setGuids(modifiedGuids);
    } else {
      setGuids([
        ...guids,
        guid
      ])
    }
  }

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const onModalOpen = (value, content) => {
    setSelectedUser(data.find(({id}) => id === value));
    setModalContent(content);
    toggleModal();
  };

  const columns = [
    {
      accessor: 'guids',
      Cell: ({row: {original}}) => (
        original.guid && (
          <FormGroup className="form-group_checkbox">
            <Label check>
              <Input
                type="checkbox"
                name="test"
                checked={guids.includes(original.guid)}
                onChange={() => toggleGuids(original.guid)}
              />{' '}
              <span />
            </Label>
          </FormGroup>
        )
      ),
    },
      {
        Header: 'Guid',
        accessor: 'guid',
        Cell: ({row: {original}}) => (
          <Link to={`/admin/user-status/${original.id}`}>{original.guid}</Link>
        ),
      },
      {
        Header: 'First name',
        accessor: 'first_name',
      },
      {
        Header: 'Last name',
        accessor: 'last_name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Latest Activity',
        accessor: 'last_time_run_test',
      },
      {
        Header: 'Last restart',
        accessor: 'last_restart_time',
        Cell: ({row: {original}}) => (
          original.last_restart_time ? moment(original.last_restart_time).format('MM/DD/YYYY hh:mm A') : 'N/A'
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row: { original } }) => original?.status ? capitalizeFirstLetter(original.status) : ''
      },
      {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({value}) => (
          value && value.length > 20 ? `${value.substring(0, 20)} ...` : value
        ),
      },
       {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({value}) => (
          <div className="actions">
            <Button
              generalType="default"
              type="button"
              onClick={() => onModalOpen(value, 'edit')}
              title="Edit"
            >
              <i className="mdi mdi-pencil" />
            </Button>
            <Button
              generalType="default"
              className="ml-2"
              type="button"
              onClick={() => onModalOpen(value)}
              title="Delete"
            >
              <i className="mdi mdi-delete" />
            </Button>
          </div>
        ),
      },
    ];

  const onSuccessSubmit = () => {
    fetch();
    setSelectedUser(null);
    toggleModal();
    toastr.success('System', 'Success user update');
  };

  const onSort = useCallback(({sortBy}) => {
    if (onSortChange) {
      if (sortBy.length > 0) {
        const [{id, desc}] = sortBy;
        onSortChange(`${[id]} ${desc ? 'desc' : 'asc'}`);
      } else {
        onSortChange();
      }
    }
  }, []);

  const deleteUser = (id, guid) => {
    dispatchDeleteUser(id);
    toggleModal();
    toastr.success('System', `User ${guid} removed successfully`);
  };

  const isEditMode = modalContent === 'edit'
  const modalHeaderTitle = isEditMode ? 'Edit user' : `Are you sure you want to remove user ${selectedUser?.guid}?`

  return (
    <>
      <ReactTable
        columns={columns}
        data={data}
        onSortChange={onSort}
        loading={isLoading}
        manualSortBy
        disableMultiSort
        disableSortBy={disableSortBy}
      />

      {
        selectedUser && (
          <Modal isOpen={isModalOpen} toggle={toggleModal} className="custom">
            <ModalHeader toggle={toggleModal}>{modalHeaderTitle}</ModalHeader>
            {isEditMode && (
              <ModalBody>
                <UserForm
                  user={selectedUser}
                  onSuccessSubmit={onSuccessSubmit}
                />
              </ModalBody>
            )}
            <ModalFooter>
              {isEditMode && (
                <>
                  <Button
                    generalType="default"
                    onClick={toggleModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    form="user-form"
                    generalType="default"
                  >
                    Submit
                  </Button>
                </>
              )}
              {!isEditMode && (
                <>
                  <Button generalType="default" onClick={toggleModal}>No, Cancel</Button>
                  <Button
                    type="submit"
                    form="user-form"
                    generalType="default"
                    onClick={() => deleteUser(selectedUser?.id, selectedUser?.guid)}
                  >
                    Yes, Remove
                  </Button>
                </>
              )}
            </ModalFooter>
          </Modal>
        )
      }

    </>

  );
};

UsersTable.defaultProps = {
  data: [],
  isLoading: false,
};

UsersTable.propTypes = {
  fetch: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any),
  onSortChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { error, loading, data } = state.Users;
  return { error, loading, data: data?.users };
};

const mapDispatchToProps = {
  dispatchDeleteUser: deleteUser,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersTable));
