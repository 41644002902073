import React, { useEffect, useState } from 'react';
import {Col, Input, FormGroup, Label, Row} from 'reactstrap';
import { useFormik } from 'formik';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import * as Yup from 'yup';
import { getHelpdeskMessage, updateHelpdeskMessage } from '../../store/actions';
import Button from '../Button/Button';
import ConfirmationModal from '../ConfirmationModal';
import ExtensionConfigurationFormItem from './ExtensionConfigurationFormItem';

// eslint-disable-next-line no-useless-escape
const URL = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

const validationSchema = Yup.object().shape({
  trigger_url: Yup.string().when('isTriggerUrlEnabled', {
    is: true,
    then: Yup.string().matches(URL, 'URL is invalid').required(),
    otherwise: Yup.string(),
  }),
});

const ExtensionConfigurationForm = ({
  dispatchGetHelpdeskMessage,
  dispatchUpdateHelpdeskMessage,
  isHelpdeskMessageLoading,
  helpdeskMessage,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const {
    chrome_new_connection_auto_test,
    chrome_daily_auto_test,
    last_restart_time_threshold,
    test_frequency_hours,
    extension_notification,
    store_email
  } = helpdeskMessage || {};
  useEffect(() => {
    dispatchGetHelpdeskMessage();
  }, []);
  const preValueOfTestFrequencyHours = (test_frequency_hours === null || test_frequency_hours === 0) ? 24 : test_frequency_hours;
  const formik = useFormik({
    initialValues: {
      chrome_new_connection_auto_test: chrome_new_connection_auto_test || false,
      chrome_daily_auto_test: chrome_daily_auto_test || false,
      last_restart_time_threshold: last_restart_time_threshold || 0,
      test_frequency_hours: preValueOfTestFrequencyHours,
      extension_notification: extension_notification || false,
      store_email: store_email || false,
    },
    validationSchema,
    onSubmit: (values) => {
      dispatchUpdateHelpdeskMessage(values);
    },
    enableReinitialize: true,
  });

  const pluralText = (value, text) => (value !== 1 ? `${text}s` : text);

  const onConfirmClick = () => {
    setIsConfirmModalOpen(false);
    formik.setFieldValue('store_email', false);
  };

  return (
    <div className="settings-tab-block">
      <div className="title">Global settings for the extension users</div>
      <div className="subtitle">
        Select the options below to configure the behavior and features for end users. These settings may take up to an hour to go into effect
      </div>
      <form className="form form-extension" onSubmit={formik.handleSubmit}>
        <Row>
          <Col xl="6">
            <div className="settings-tab-item">
              <Row>
                <Col md={7}>
                  <FormGroup tag="fieldset">
                    <ExtensionConfigurationFormItem
                      label="Automatically test new networks"
                      name="chrome_new_connection_auto_test"
                      value={formik.values.chrome_new_connection_auto_test}
                      action={formik.handleChange}
                      description="Automatically run a test when a new internet connection is detected"
                    />
                    <ExtensionConfigurationFormItem
                      label="Recurring testing"
                      name="chrome_daily_auto_test"
                      value={formik.values.chrome_daily_auto_test}
                      action={formik.handleChange}
                      description="Triger a network test if the most recent results are over"
                    >
                      <>
                        <div className="inline-input">
                          {formik.values.chrome_daily_auto_test && (
                          <Input
                            type="number"
                            id="test_frequency_hours"
                            name="test_frequency_hours"
                            value={formik.values.test_frequency_hours}
                            className={`form-control ${formik.errors.test_frequency_hours ? 'is-invalid' : ''}`}
                            onChange={(e) => formik.handleChange(e)}
                            min="1"
                          />
                          )}
                        </div>
                        {pluralText(formik.values.test_frequency_hours, 'hour')}
                      </>
                    </ExtensionConfigurationFormItem>
                  </FormGroup>

                  <FormGroup tag="fieldset">
                    <ExtensionConfigurationFormItem
                      label="Device Restart Notification"
                      name="extension_notification"
                      value={formik.values.extension_notification}
                      action={formik.handleChange}
                      description="Notify user weekly if their device hasn&#39;t been restarted in over"
                    >
                      <>
                        <div className="inline-input">
                          {formik.values.extension_notification && (
                          <Input
                            type="number"
                            id="last_restart_time_threshold"
                            name="last_restart_time_threshold"
                            value={formik.values.last_restart_time_threshold}
                            className={`form-control ${formik.errors.last_restart_time_threshold ? 'is-invalid' : ''}`}
                            onChange={(e) => formik.handleChange(e)}
                            min="0"
                            max="99"
                          />
                          )}
                        </div>
                        {formik.values.last_restart_time_threshold !== 1 ? 'days' : 'day'}
                      </>
                    </ExtensionConfigurationFormItem>
                    <ExtensionConfigurationFormItem
                      label="Storing User Email"
                      name="store_email"
                      value={formik.values.store_email}
                      action={formik.handleChange}
                      setIsConfirmModalOpen={setIsConfirmModalOpen}
                      description="Only available for Extension V3"
                    />
                  </FormGroup>

                  <div className="form-btn d-flex justify-content-between">
                    <Button
                      generalType="default"
                      type="submit"
                      disabled={isHelpdeskMessageLoading}
                    >
                      Save changes
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </form>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        bodyText="Once you deactivate this settings all user emails will be removed."
        confirm={onConfirmClick}
        reject={() => setIsConfirmModalOpen(false)}
      />
    </div>
  );
};

ExtensionConfigurationForm.defaultProps = {
};

ExtensionConfigurationForm.propTypes = {
  dispatchUpdateHelpdeskMessage: PropTypes.func.isRequired,
  isHelpdeskMessageLoading: PropTypes.bool.isRequired,
  helpdeskMessage: PropTypes.shape({
    chrome_head_request: PropTypes.string,
    chrome_sub_head_request: PropTypes.string,
    support_email: PropTypes.string,
    custom_redirect_url: PropTypes.string,
  }),
};

const mapStateToProps = (state) => {
  const {isHelpdeskMessageLoading, helpdeskMessage = {}} = state.EXConfig;
  return {isHelpdeskMessageLoading, helpdeskMessage};
};

const mapDispatchToProps = {
  dispatchGetHelpdeskMessage: getHelpdeskMessage,
  dispatchUpdateHelpdeskMessage: updateHelpdeskMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionConfigurationForm);
