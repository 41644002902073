export const GET_MY_PROFILE_REQUEST = 'GET_MY_PROFILE_REQUEST';
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS';
export const GET_MY_PROFILE_ERROR = 'GET_MY_PROFILE_ERROR';

export const UPDATE_MY_PROFILE_REQUEST = 'UPDATE_MY_PROFILE_REQUEST';
export const UPDATE_MY_PROFILE_SUCCESS = 'UPDATE_MY_PROFILE_SUCCESS';
export const UPDATE_MY_PROFILE_ERROR = 'UPDATE_MY_PROFILE_ERROR';

export const UPDATE_LAST_RESTART_TIME = 'UPDATE_LAST_RESTART_TIME';

export const UPDATE_MY_PROFILE_DATA = 'UPDATE_MY_PROFILE_DATA';